






































































































import { FileHeader, FileHeaderReader } from '@/lib/desktop/FileHeaderReader';
import { getSaveGamesFolderPath } from '@/lib/desktop/getSaveGamesFolderPath';
import { commithash } from '@lib/commithash';
import {
  DIALOG_ABOUT,
  DIALOG_CONFIRM_EXIT_DESKTOP,
  DIALOG_SAVE_DESKTOP,
  DIALOG_SETTINGS
} from '@lib/constants';
import { EventBus } from '@lib/event-bus';
import { remote } from 'electron';
import fs, { createReadStream, Dir } from 'fs';
import path from 'path';
import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
// TODO use SaveFileReader/Writer instead?
import { openFileAndMoveToEditor } from '../../lib/desktop/DesktopFileReader';
import { saveFileAndShowProgress } from '../../lib/desktop/DesktopFileWriter';

interface FileList {
  [id: string]: FileHeader[];
}

@VueComponent({
  computed: {
    ...mapState(['showSaveMenuEntries'])
  } /*,
  watch: {
    showSaveMenuEntries() {
      // update the menu
    }
  }*/
})
export default class DesktopMenu extends Vue {
  @Prop({ default: false }) readonly visible!: boolean;

  private commithash = commithash;
  private files: { [id: string]: FileHeader[] } = {};
  private saveFolderNotFound = false;
  private sessionFiles = [];
  private showFilebrowser = false;
  private version = remote.app.getVersion();
  private isLoadingFilelist = false;
  private sortedFiles: { sessionName: string; saves: FileHeader[] }[] = [];

  mounted() {
    // read files
    this.reloadFilelist();
  }

  reloadFilelist() {
    this.readFiles(getSaveGamesFolderPath());
  }

  readFiles(dir: string) {
    this.isLoadingFilelist = true;
    // For some reason this hangs the first time in debug builds until the page is refreshed
    // This is no problem in production builds
    fs.opendir(dir, async (err, iterator) => {
      if (err) {
        this.saveFolderNotFound = true;
        this.isLoadingFilelist = false;
        // TODO: SaveGames folder not found
        return;
      }

      // store our fresh copy of the file array here until all are loaded
      const freshFiles: FileList = {};
      await this.parseFilelist(freshFiles, dir, iterator);

      this.sortedFiles = this.sortFiles(freshFiles);
      this.isLoadingFilelist = false;
    });
  }

  async parseFilelist(freshFiles: FileList, dir: string, iterator: Dir) {
    let dirent;
    while ((dirent = iterator.readSync()) !== null) {
      const file = dirent.name;
      const filePath = path.resolve(dir, file);

      const stat = fs.statSync(filePath);
      if (stat && stat.isDirectory()) {
        const iter = fs.opendirSync(filePath);
        await this.parseFilelist(freshFiles, filePath, iter);
      } else {
        if (file.endsWith('.sav')) {
          const header = await this.readFileHeader(file, filePath);
          // add file size to header
          header.size = stat.size;

          if (freshFiles[header.sessionName] === undefined) {
            freshFiles[header.sessionName] = [];
            //this.$set(this.files, header.sessionName, []);
          }

          freshFiles[header.sessionName].push(header);
        }
      }
    }
    iterator.closeSync();
  }

  async readFileHeader(file: string, filePath: string): Promise<any> {
    return new Promise<any>(resolve => {
      // READ HEADER OF SAVE FILE
      const stream = createReadStream(path.join(filePath));
      new FileHeaderReader(file, filePath, stream, header => {
        resolve(header);
      });
    });
  }

  sortFiles(
    freshFiles: FileList
  ): { sessionName: string; saves: FileHeader[] }[] {
    // sort files
    const sortedFiles = [];

    for (const key of Object.keys(freshFiles)) {
      // sort saves
      freshFiles[key].sort((a, b) => {
        return b.saveDateTime.getTime() - a.saveDateTime.getTime();
      });

      sortedFiles.push({
        sessionName: key,
        saves: freshFiles[key]
      });
    }

    // sort sessions
    sortedFiles.sort((a, b) => {
      return (
        b.saves[0].saveDateTime.getTime() - a.saves[0].saveDateTime.getTime()
      );
    });
    return sortedFiles;
  }

  saveFile() {
    // show confirmation dialog
    EventBus.$emit(DIALOG_SAVE_DESKTOP);
  }

  openFilebrowser() {
    // reload file list TODO do this whenever this menu is shown?
    // this.readFiles(getSaveGamesFolderPath());

    this.showFilebrowser = !this.showFilebrowser;
    if (this.showFilebrowser === false) {
      // also empty the session list
      this.sessionFiles = [];
    } else {
      // reload files
      // TODO only if some time has passed?
      this.reloadFilelist();
    }
  }

  exportJson() {
    // TODO deduplicate with DesktopApp.openJsonSaveSelector
    const name = this.$store.state.filename.replace('.sav', '.json');

    remote.dialog
      .showSaveDialog({
        title: this.$t('desktop.saveJsonTitle') as string,
        defaultPath: name,
        filters: [
          {
            name: this.$t('desktop.jsonExtension') as string,
            extensions: ['json']
          }
        ]
      })
      .then(value => {
        if (value.canceled) {
          return;
        }

        saveFileAndShowProgress(this, value.filePath!, true, false);
      });
  }

  openSettings() {
    EventBus.$emit(DIALOG_SETTINGS);
  }
  openAbout() {
    EventBus.$emit(DIALOG_ABOUT);
  }
  openExit() {
    EventBus.$emit(DIALOG_CONFIRM_EXIT_DESKTOP);
  }

  openFile(filepath: string) {
    openFileAndMoveToEditor(this, filepath, false);
  }

  openJsonFilebrowser() {
    // TODO deduplicate with openJsonFileSelector in DesktopApp
    remote.dialog
      .showOpenDialog({
        title: this.$t('desktop.openJsonTitle').toString(),
        defaultPath: getSaveGamesFolderPath(),
        filters: [
          {
            name: this.$t('desktop.jsonExtension').toString(),
            extensions: ['json']
          }
        ]
      })
      .then(result => {
        if (result.filePaths.length === 1) {
          openFileAndMoveToEditor(this, result.filePaths[0], true);
        }
      });
  }

  /**
   * Formats the last save time
   */
  dateToString(date: Date): string {
    return date.toLocaleDateString(this.$i18n.locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
  }

  /**
   * Formats the current time played
   */
  secondsToTime(seconds: number): string {
    // TODO localize?
    if (seconds < 60) {
      return Math.floor(seconds) + ' s';
    }
    seconds /= 60;
    if (seconds < 60) {
      return Math.floor(seconds) + ' m';
    }
    seconds /= 60;
    return Math.floor(seconds) + ' h';
  }
  /**
   * Formats the save game size
   */
  bytesToSize(bytes: number): string {
    // TODO localize?
    // https://stackoverflow.com/a/20732091
    let i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
      (bytes / Math.pow(1024, i)).toFixed(2) +
      ' ' +
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    );
  }
}
