

























































import CenterWhiteBox from '@/components/core/CenterWhiteBox.vue';
import LanguageSwitcher from '@/components/core/LanguageSwitcher.vue';
import Logo from '@/components/core/Logo.vue';
import { commithash } from '@lib/commithash';

export default {
  name: 'LandingPage',
  components: {
    Logo,
    LanguageSwitcher,
    CenterWhiteBox
  },
  data: function() {
    return {
      logoAnimating: false,
      commithash: commithash
    };
  },
  mounted() {},
  methods: {
    downloadDesktop() {
      location.href =
        'https://github.com/ficsit-felix/ficsit-felix/releases/latest';
    }
  }
};
