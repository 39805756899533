

























import { DIALOG_PROGRESS } from '@lib/constants';
import { EventBus } from '@lib/event-bus';
import { Component as VueComponent, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';

@VueComponent({
  components: {
    // ProgressBar
  },
  computed: {
    ...mapState(['progress', 'progressText'])
  }
})
export default class ProgressBarDialog extends Vue {
  hideProgressDialog() {
    EventBus.$emit(DIALOG_PROGRESS, false);
  }
}
