

































































































































import {
  DIALOG_ABOUT,
  DIALOG_BUGREPORT,
  DIALOG_CONFIRM_EXIT_DESKTOP,
  DIALOG_HELP,
  DIALOG_OPEN_JSON_WEB,
  DIALOG_OPEN_SOURCE,
  DIALOG_OPEN_WEB,
  DIALOG_PROGRESS,
  DIALOG_SAVE_DESKTOP,
  DIALOG_SAVE_JSON_WEB,
  DIALOG_SAVE_WEB,
  DIALOG_SETTINGS,
  ON_EXIT_PRESSED,
  ON_SAVE_JSON_PRESSED,
  ON_SAVE_PRESSED
} from '@lib/constants';
import { EventBus } from '@lib/event-bus';
import { setTimeout } from 'timers';
import Vue from 'vue';
import { mapState } from 'vuex';
import Settings from '../Settings.vue';
import BugReportDialog from './BugReportDialog.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import HelpDialog from './HelpDialog.vue';
import LicensesDialog from './LicensesDialog.vue';
import ProgressBarDialog from './ProgressBarDialog.vue';
// TODO rework this dialog system to avoid repeating code

export default Vue.extend({
  name: 'Dialogs',
  components: {
    LicensesDialog,
    Settings,
    ProgressBarDialog,
    ConfirmDialog,
    BugReportDialog,
    HelpDialog
  },
  data: function() {
    return {
      showHelpDialog: false,
      showSettingsDialog: false,
      showLicensesDialog: false,
      showAboutDialog: false,
      showProgressDialog: false,
      showSaveWebDialog: false,
      showSaveDesktopDialog: false,
      showConfirmExitDesktopDialog: false,
      showOpenWebDialog: false,
      showOpenJsonWebDialog: false,
      showSaveJsonWebDialog: false
    };
  },
  computed: {
    ...mapState(['filename', 'uuid'])
  },
  mounted() {
    EventBus.$on(DIALOG_HELP, () => {
      this.closeDialogs(
        this.showHelpDialog,
        () => (this.showHelpDialog = true)
      );
    });
    EventBus.$on(DIALOG_OPEN_SOURCE, () => {
      this.closeDialogs(
        this.showLicensesDialog,
        () => (this.showLicensesDialog = true)
      );
    });
    EventBus.$on(DIALOG_ABOUT, () => {
      this.closeDialogs(
        this.showAboutDialog,
        () => (this.showAboutDialog = true)
      );
    });
    EventBus.$on(DIALOG_SETTINGS, () => {
      this.closeDialogs(
        this.showSettingsDialog,
        () => (this.showSettingsDialog = true)
      );
    });
    EventBus.$on(DIALOG_PROGRESS, (val: boolean) => {
      if (val) {
        this.closeDialogs(
          this.showProgressDialog,
          () => (this.showProgressDialog = val)
        );
      } else {
        this.showProgressDialog = false;
      }
    });
    EventBus.$on(DIALOG_SAVE_WEB, () => {
      this.closeDialogs(
        this.showSaveWebDialog,
        () => (this.showSaveWebDialog = true)
      );
    });
    EventBus.$on(DIALOG_SAVE_DESKTOP, () => {
      this.closeDialogs(
        this.showSaveDesktopDialog,
        () => (this.showSaveDesktopDialog = true)
      );
    });
    EventBus.$on(DIALOG_BUGREPORT, (errorMessage: string) => {
      this.closeDialogs(false, () => {
        (this.$refs.bugReport as any).openReportWindow(
          this.$t('savePage.error') + ' ' + errorMessage
        );
      });
    });
    EventBus.$on(DIALOG_CONFIRM_EXIT_DESKTOP, () => {
      this.closeDialogs(
        this.showConfirmExitDesktopDialog,
        () => (this.showConfirmExitDesktopDialog = true)
      );
    });
    EventBus.$on(DIALOG_OPEN_WEB, () => {
      this.closeDialogs(
        this.showOpenWebDialog,
        () => (this.showOpenWebDialog = true)
      );
    });
    EventBus.$on(DIALOG_OPEN_JSON_WEB, () => {
      this.closeDialogs(
        this.showOpenJsonWebDialog,
        () => (this.showOpenJsonWebDialog = true)
      );
    });
    EventBus.$on(DIALOG_SAVE_JSON_WEB, () => {
      this.closeDialogs(
        this.showSaveJsonWebDialog,
        () => (this.showSaveJsonWebDialog = true)
      );
    });
  },
  beforeDestroy() {
    EventBus.$off(DIALOG_HELP);
    EventBus.$off(DIALOG_OPEN_SOURCE);
    EventBus.$off(DIALOG_ABOUT);
    EventBus.$off(DIALOG_SETTINGS);
    EventBus.$off(DIALOG_PROGRESS);
    EventBus.$off(DIALOG_SAVE_WEB);
    EventBus.$off(DIALOG_SAVE_DESKTOP);
    EventBus.$off(DIALOG_BUGREPORT);
    EventBus.$off(DIALOG_CONFIRM_EXIT_DESKTOP);
    EventBus.$off(DIALOG_OPEN_WEB);
    EventBus.$off(DIALOG_OPEN_JSON_WEB);
    EventBus.$off(DIALOG_SAVE_JSON_WEB);
  },
  methods: {
    closeDialogs(dialogAlreadyOpenDontClose: boolean, callback: () => void) {
      if (dialogAlreadyOpenDontClose === true) {
        callback();
        return;
      }
      const dialogPreviouslyOpen =
        this.showHelpDialog ||
        this.showSettingsDialog ||
        this.showLicensesDialog ||
        this.showAboutDialog ||
        this.showProgressDialog ||
        this.showSaveWebDialog ||
        this.showSaveDesktopDialog ||
        this.showConfirmExitDesktopDialog ||
        this.showOpenWebDialog ||
        this.showOpenJsonWebDialog ||
        this.showSaveJsonWebDialog;
      this.closeAllDialogs();
      if (dialogPreviouslyOpen) {
        // wait for the dialog to close
        setTimeout(callback, 100);
      } else {
        callback();
      }
    },
    closeAllDialogs() {
      this.showHelpDialog = false;
      this.showSettingsDialog = false;
      this.showLicensesDialog = false;
      this.showAboutDialog = false;
      this.showProgressDialog = false;
      this.showSaveWebDialog = false;
      this.showSaveDesktopDialog = false;
      this.showConfirmExitDesktopDialog = false;
      this.showOpenWebDialog = false;
      this.showOpenJsonWebDialog = false;
      this.showSaveJsonWebDialog = false;
    },

    open() {
      this.$router.push('open/sav');
    },
    openJson() {
      this.$router.push('open/json');
    },
    save() {
      EventBus.$emit(ON_SAVE_PRESSED);
    },
    saveJson() {
      EventBus.$emit(ON_SAVE_JSON_PRESSED);
    },

    exit() {
      EventBus.$emit(ON_EXIT_PRESSED);
    }
  }
});
