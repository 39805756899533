






import { Component as VueComponent, Vue } from 'vue-property-decorator';
import DesktopMenu from './DesktopMenu.vue';

@VueComponent({
  components: {
    DesktopMenu
  }
})
export default class MainScreen extends Vue {
  mounted() {
    // hide save menu entries
    this.$store.dispatch('setShowSaveMenuEntries', false);

    if (this.$store.state.settings.autoLoadSaveFile !== '') {
      this.$router.push({
        path: 'open/auto'
      });
    }
  }
}
