














































































import * as JSZip from 'jszip';
import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';
import 'whatwg-fetch';
import AlertDialog from './AlertDialog.vue';

@VueComponent({ components: { AlertDialog } })
export default class BugReportDialog extends Vue {
  @Prop({ default: '' }) readonly uuid!: string;
  @Prop({ default: '' }) readonly filename!: string;
  @Prop({ default: true }) readonly defaultIncludeSave!: boolean;

  message: string = '';
  showBugReportDialog = false;
  showSentDialog: boolean = false;
  showErrorDialog: boolean = false;

  userContact: string = '';
  userMessage: string = '';
  includeSave: boolean = true;
  includeScreenshot: boolean = true;
  screenshotDataUrl: string = '';

  formDisabled: boolean = false;

  openReportWindow(message: string) {
    this.message = message;
    this.showBugReportDialog = true;
    this.includeSave = this.defaultIncludeSave;

    const scene = document.getElementById('scene');
    if (scene !== undefined && scene !== null) {
      const canvas = scene.getElementsByTagName('canvas')[0];
      if (canvas !== undefined && canvas !== null) {
        this.screenshotDataUrl = canvas.toDataURL('image/jpeg');
        this.includeScreenshot = true;
      }
    } else {
      this.screenshotDataUrl = '';
      this.includeScreenshot = false;
    }
  }

  fetchFileForBugReport(file: File): Promise<Buffer> {
    return new Promise<Buffer>((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = response => {
        if (response === null || response.target === null) {
          reject();
          return;
        }
        resolve(response.target.result as Buffer);
      };
      reader.readAsArrayBuffer(file);
      // TODO error handling?
    });
  }

  readFileForBugReport(file: string): Promise<Buffer> {
    return new Promise<Buffer>((resolve, reject) => {
      const fs = require('fs');
      fs.readFile(file, (err: Error, data: Buffer) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    });
  }

  sendReport() {
    this.formDisabled = true;

    let zip = new JSZip();
    if (this.includeSave) {
      if (window.data instanceof File) {
        // The file has not been read completely, we need to reread it ourselves (browser)
        zip.file(window.data.name, this.fetchFileForBugReport(window.data));
      } else if (typeof window.data === 'string') {
        // The file has not been read completely, we need to reread it ourselves (desktop)
        zip.file(
          window.data.replace(/^.*[\\/]/, ''),
          this.readFileForBugReport(window.data)
        );
      } else if (window.data instanceof ArrayBuffer) {
        zip.file(this.filename + '.sav', window.data, { binary: true });
      } else {
        zip.file(this.filename + '.json', JSON.stringify(window.data));
      }
    }

    if (this.includeScreenshot) {
      const screenshot = Buffer.from(
        this.screenshotDataUrl.substring(
          this.screenshotDataUrl.indexOf(',') + 1
        ),
        'base64'
      );

      zip.file('screenshot.jpg', screenshot, { binary: true });
    }
    const meta = `message: ${this.message}
userContact: ${this.userContact}
userMessage: ${this.userMessage}
filename: ${this.filename}
uuid: ${this.uuid}
version: ${process.env.PACKAGE_VERSION}
`;
    zip.file('meta.txt', meta);

    zip
      .generateAsync(
        {
          type: 'uint8array',
          compression: 'DEFLATE',
          compressionOptions: {
            level: 9
          }
        } /*,
        (metadata: any) => {
          console.log(metadata.percent);
        }*/
      )
      .then((content: Uint8Array) => {
        window
          .fetch(
            'https://owl.yt/ficsit-felix/?uuid=' +
              this.uuid +
              '&message=' +
              Buffer.from(this.message).toString('base64'),
            {
              method: 'POST',
              body: content, //Buffer.from(content, 'base64').toString('ascii'),
              headers: {
                'Content-Type': 'application/octet-stream'
              }
            }
          )
          .then(response => {
            if (!response.ok) {
              return Promise.reject('http ' + response.status);
            }
            return response.json();
          })
          .then(response => {
            this.showBugReportDialog = false;
            this.formDisabled = false;
            this.showSentDialog = true;
            // prepare for possible next report
            this.userMessage = '';
            this.includeSave = true;
          })
          .catch((error: Error) => {
            this.showBugReportDialog = false;
            this.formDisabled = false;
            console.error(error);
            this.showErrorDialog = true;
          });
      })
      .catch((error: Error) => {
        this.showBugReportDialog = false;
        this.formDisabled = false;
        console.error(error);
        this.showErrorDialog = true;
      });
  }
}
