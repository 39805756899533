export const DIALOG_OPEN_TIME_MS = 300;
export const GUI_REFRESH_TIMEOUT = 16;

// Dialogs
export const DIALOG_HELP = 'DIALOG_HELP';
export const DIALOG_OPEN_SOURCE = 'DIALOG_OPEN_SOURCE';
export const DIALOG_ABOUT = 'DIALOG_ABOUT';
export const DIALOG_SETTINGS = 'DIALOG_SETTINGS';
export const DIALOG_PROGRESS = 'DIALOG_PROGRESS';
export const DIALOG_SAVE_WEB = 'DIALOG_SAVE_WEB';
export const DIALOG_SAVE_DESKTOP = 'DIALOG_SAVE_DESKTOP';
export const DIALOG_BUGREPORT = 'DIALOG_BUGREPORT';
export const DIALOG_CONFIRM_EXIT_DESKTOP = 'DIALOG_CONFIRM_EXIT_DESKTOP';
export const DIALOG_OPEN_WEB = 'DIALOG_OPEN_WEB';
export const DIALOG_OPEN_JSON_WEB = 'DIALOG_OPEN_JSON_WEB';
export const DIALOG_SAVE_JSON_WEB = 'DIALOG_SAVE_JSON_WEB';

// Dialog responses
export const ON_SAVE_PRESSED = 'ON_SAVE_PRESSED';
export const ON_SAVE_JSON_PRESSED = 'ON_SAVE_JSON_PRESSED';
export const ON_EXIT_PRESSED = 'ON_EXIT_PRESSED';

// other events
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const FOCUS_SELECTED_OBJECT = 'FOCUS_SELECTED_EVENT';
export const DELETE_OBJECTS = 'DELECT_OBJECTS';
export const CREATE_OBJECTS = 'CREATE_OBJECTS';
export const CAMERA_CHANGE = 'CAMERA_CHANGE';
export const SCENE_RESIZE = 'SCENE_RESIZE';
