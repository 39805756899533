var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700","value":_vm.value,"scrollable":""},on:{"input":function (newValue) {
      this$1.$emit('input', newValue);
    }}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('dialog.help.title')))]),_c('v-card-text',[_c('b',[_vm._v(_vm._s(_vm.$t('dialog.help.controlsTitle')))]),_c('table',{staticClass:"mb-4"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('dialog.help.headerKey')))]),_c('th',[_vm._v(_vm._s(_vm.$t('dialog.help.headerAction')))])]),_vm._l((_vm.keymap),function(ref,index){
    var key = ref.key;
    var action = ref.action;
return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[_vm._v(_vm._s(action))])])})],2),_c('b',[_vm._v(_vm._s(_vm.$t('dialog.help.warningTitle')))]),_c('p',[_vm._v(_vm._s(_vm.$t('dialog.help.changeJsonWarning')))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('general.close')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }