






















































































































































































































































import { gsap, Power2, Power3 } from 'gsap';
import {
  Component as VueComponent,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator';

@VueComponent({
  //props: ['height', 'black', 'animating']
})
export default class Logo extends Vue {
  @Prop(Number) readonly height: number | undefined;
  @Prop(String) readonly black: string | undefined;
  @Prop(Boolean) readonly animating: boolean | undefined;
  private tl!: gsap.core.Timeline | null;

  @Watch('animating', { immediate: true })
  onAnimatingChanged(val: boolean) {
    if (val && this.tl !== null) {
      if (!this.tl.isActive()) {
        this.tl.restart();
      } else {
        this.tl.play();
      }
    }
  }

  mounted() {
    this.tl = gsap.timeline({
      onComplete: () => {
        if (this.animating && this.tl !== null) {
          this.tl.restart();
        }
      },
      paused: !this.animating
    });
    if (this.tl !== null) {
      const hammer = this.$refs.hammer as any;
      this.tl.set(hammer, { transformOrigin: '80% 64%' });
      this.tl.to(hammer, 0.5, { rotation: 50, ease: Power2.easeIn });
      this.tl.to(hammer, 0.2, { rotation: -10, ease: Power3.easeIn });
      this.tl.to(hammer, 0.3, { rotation: 0, ease: Power2.easeOut });
    }
  }

  beforeDestroy() {
    if (this.tl !== null) {
      this.tl.kill();
      this.tl = null;
    }
  }
}
