






import { Component as VueComponent, Vue } from 'vue-property-decorator';

@VueComponent({})
export default class CenterWhiteBox extends Vue {}
