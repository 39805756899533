
























import {
  Component as VueComponent,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator';

/**
 * Simple component for a confirm dialog that also reacts to ESC and Enter
 */
@VueComponent({})
export default class ConfirmDialog extends Vue {
  @Prop() value!: boolean;
  @Prop() title!: string;
  @Prop() content!: string;

  @Watch('value') valueChange(newVal: boolean) {
    if (newVal) {
      document.addEventListener('keyup', this.onEnterPressed);
    } else {
      document.removeEventListener('keyup', this.onEnterPressed);
    }
  }

  onEnterPressed(e: any) {
    if (e.keyCode === 13) {
      e.stopPropagation();
      this.choose(true);
    }
  }
  choose(value: boolean) {
    this.$emit('input', false);
    if (value) {
      this.$emit('confirm');
    }
  }
}
