























import { Component as VueComponent, Prop, Vue } from 'vue-property-decorator';

/**
 * Simple component for an alert dialog
 */
@VueComponent({})
export default class AlertDialog extends Vue {
  @Prop() value!: boolean;
  @Prop() title!: string;
  @Prop() content!: string;

  close() {
    this.$emit('input', false);
  }
}
